import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatisticsTable, Modal } from "../../components";
import {
  getStatistics, getDateRangeEnums, getDateInFullYearFormat, getDateInFormat, continuityActions,
  getTestStatusTypes
} from "../../store";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Columns = [
  { label: '', attribute: 'label', headerClassName: 'txt-left', width: '10%' },
  { label: 'Total', attribute: 'overall', headerClassName: 'bgDDFFFF', width: '10%' },
  { label: 'Closed', attribute: 'closed', headerClassName: 'published', width: '10%' },
  { label: 'InProgress', attribute: 'inprogress', headerClassName: 'inprogress', width: '10%' },
  { label: 'Open', attribute: 'open', headerClassName: 'cFE3333', width: '10%' },
  { label: 'Minimal', attribute: 'minimal', headerClassName: 'c777', width: '10%' },
  { label: 'Other', attribute: 'other', headerClassName: 'c777', width: '10%' },
]

export const TestStatistics = (props) => {
  const [exportConfig, setExportConfig] = React.useState(null);
  const [isExporting, setIsExporting] = React.useState(false);
  const dispatch = useDispatch()
  const dateRangeEnumns = useSelector(getDateRangeEnums);
  const statistics = useSelector(getStatistics)
  const status = useSelector(getTestStatusTypes);

  React.useEffect(() => {
    dispatch(continuityActions.fetchDateRangeEnums());
    dispatch(continuityActions.fetchStatusList());
  }, []);

  const handleFetchDepartmentStats = (dateRange) => {
    if (dateRange && dateRange.id) {
      dispatch(continuityActions.fetchDeptStats({ dateRangeType: dateRange.id }))
    }
  }
  const handleExport = (type, dateRangeType) => {
    const label = dateRangeType.label.replaceAll(" ", "_");
    const filename = `Fusion_Continuity_Stats_${label} ${getDateInFormat(undefined, "YYMMDD")}.png`;
    setExportConfig({ type, filename, dateRangeType: dateRangeType })
    setIsExporting(true)
  }
  const handleCloseExport = () => {
    setIsExporting(false)
    setExportConfig(null)
  }
  const handleConfirmExport = async () => {
    // Show loading message
    const loadingElement = document.getElementById('loader-component')
    loadingElement.style.display = 'block'
    const input = document.getElementById('continuity_table');
    let originalDisplay = input.style.display
    input.style.display = 'block'
    let canvas;
    try {
      canvas = await html2canvas(input, {
        scrollY: -window.scrollY,
        letterRendering: 1,
        allowTaint: true,
        useCORS: true
      });
      const a4Width = 1000;
      const a4Height = 700;
      const margin = 40;
      const newCanvas = document.createElement('canvas');
      newCanvas.width = a4Width;
      newCanvas.height = a4Height;
      const context = newCanvas.getContext('2d');
      context.fillStyle = 'white';
      context.fillRect(0, 0, newCanvas.width, newCanvas.height);
      const scale = Math.min(a4Width / canvas.width, (a4Height - margin) / canvas.height);
      context.drawImage(canvas, margin / 2, margin / 2, canvas.width * scale, canvas.height * scale);
      const imgData = newCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      let fileName = (exportConfig && exportConfig.filename) || 'Fusion_Continuity_Stats.png';
      link.href = imgData;
      link.download = `${fileName}`;
      link.click();
      input.style.display = originalDisplay
    } catch (error) {
      console.error('Error exporting image:', error);
    } finally {
      setTimeout(() => {
        setExportConfig(null);
        setIsExporting(false);
        loadingElement.style.display = 'none'
      }, 0);
    }
  };
  return (
    <div className="col w-100 h-100 oy-auto">
      <div className="col test-stats">
        <StatisticsTable
          Columns={Columns}
          statusAs={getDateInFullYearFormat()}
          DateRangeEnums={dateRangeEnumns}
          title='Department-wise Status'
          rows={statistics.department}
          onDateChange={handleFetchDepartmentStats}
          onExport={handleExport.bind(null, 'dept')}
          isExporting={isExporting}
        />
      </div>
      <Modal
        open={Boolean(exportConfig)}
        width={517}
        actions={[
          { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC', onClick: () => handleCloseExport() },
          { label: 'Export', onClick: handleConfirmExport },
        ]}
        title={<span className="exo2 f20 bold c238787">Export Stats?</span>}>
        <p className="f14 reg 00085">Stats will be exported as a PNG Image.<br />File Name: <span className="bold">{exportConfig && exportConfig.filename}</span></p>
      </Modal>
    </div>
  )
}


// const getDepartmentStats = () => {
//   return statistics.department.map((_) => {
//     return { ..._, labelClassName: `bg${_.id}` }
//   })
// }
// const getColumns = () => {
//   let columns = status.map((_) => {
//     const id = String(_.id).toLowerCase()
//     return {label: _.label, attribute: id, headerClassName: `status ${id}`}
//   })
//   return Columns.concat(columns)
// }
// const handleConfirmExport = () => {
//   const { filename, dateRangeType } = exportConfig;
//   dispatch(continuityActions.exportDeptStats({ filename, dateRangeType }))
//   setExportConfig(null)
// }