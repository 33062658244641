import "./saga";
export * from "./slice";

const getCommonState = (state) => state.common;

const getDeptResponders = (state, property) => {
  const _state = getCommonState(state);
  const depts = getDepartments(state);
  let deptResponders = _state[property];
  let responders = [];
  if (depts.length > 0) {
    responders = depts.map((_) => {
      let item = { departmentLabel: _.label, departmentId: _.id }
      let responder = deptResponders.find((resp) => (resp.department_name === _.id));
      if (responder) {
        item = { ...item, ...responder }
      }
      return item;
    })
  }
  return responders;
}

export const getDepartments = (state) => {
  const _state = getCommonState(state);
  return _state.departments
}
export const getDocClassifications = (state) => {
  const _state = getCommonState(state);
  return _state.documentClassification
}
export const getDocTypes = (state) => {
  const _state = getCommonState(state);
  return _state.documentTypes
}
export const getIncidentDeptResponders = (state) => {
  return getDeptResponders(state, 'incidentDeptResponders')
}
export const getContinuityDeptResponders = (state) => {
  return getDeptResponders(state, 'continuityDeptResponders')
}
export const getDeptResponderByDept = (responders, dept, state) => {
  let list = [];
  if (Array.isArray(responders)) {
    list = responders;
  } else {
    if (responders === 'incident') {
      list = getIncidentDeptResponders(state)
    } else if (responders === 'continuity') {
      list = getContinuityDeptResponders(state)
    }
  }
  return list.find((_) => (_.departmentId === dept));
}
export const getTaskProrityTypes = (state) => {
  const _state = getCommonState(state);
  return _state.taskPriorityTypes;
}
export const getIncidentReport = (incident) => {
  if (incident) {
    return incident.attachments.find(({ title }) => {
      return title === 'saved_incident_report'
    })
  }
  return null
}
