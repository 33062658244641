import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form } from '../../../components';
import Fields from "./FormFields.json";
import { continuityActions, commonActions, getDepartments, getActiveUsers, getContinuityDeptResponders, getDeptResponderByDept, getBlueprints, getDateInFormat } from "../../../store";

const Actions = [
  { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC' },
  { label: 'Create', },
]

const DepartmentResponderConfirm = (props) => {
  const dispatch = useDispatch();
  const deptResponders = useSelector(getContinuityDeptResponders);
  const { department, responder, open } = props;
  const handleConfirm = () => {
    const payload = { department: department.id, userId: responder.id }
    dispatch(commonActions.updateDeptContinuityResponder(payload))
    props.onClose && props.onClose()
  }
  const isPrevRespExist = () => {
    let exist = true;
    if (department) {
      const deptResp = getDeptResponderByDept(deptResponders, department.id);
      exist = Boolean(deptResp) && Boolean(deptResp.ent_usr_id)
    }
    return exist
  }
  return (
    <Modal
      width={650}
      className='new-test-responder'
      open={open}
      actions={[
        { label: 'Not Now', variant: 'lite', className: 'min gap', color: '#0133CC', onClick: props.onClose },
        { label: 'Confirm', onClick: handleConfirm },
      ]}
      onCancel={props.onClose}
      title={<span className="exo2 f24 c238787">Update Responder Permanently?</span>}>
      <div className="f18 col ">
        <div>{!isPrevRespExist() && <React.Fragment>Continuity Test Responder for {department && department.label} is NOT defined.<br /></React.Fragment>}Confirm whether Continuity Responders list should be updated as well.</div>
        <br />
        <div>
          <div>Department: <b>{department && department.label || ''}</b></div>
          <div>Responder: <b>{responder && responder.label || ''}</b></div>
        </div>
      </div>
    </Modal>
  )
}

export const NewTestModal = (props) => {
  const { open } = props;
  const _ref = React.useRef(null)
  const dispatch = useDispatch();
  const departments = useSelector(getDepartments);
  const orgUsers = useSelector(getActiveUsers);
  const deptResponders = useSelector(getContinuityDeptResponders);
  const blueprints = useSelector(getBlueprints)
  const dueDate = new Date(new Date().getTime() + (15 * 24 * 60 * 60 * 1000));
  const [state, setState] = React.useState({
    showDeptResponderConfirm: false,
    test: {
      "due_date": dueDate.toISOString()
    }
  });
  const [errors, setErrors] = React.useState({});

  const resetErrors = () => {
    setErrors({});
  };
  React.useEffect(() => {
    if (!open) {
      setState((_) => ({ ..._, test: { "due_date": dueDate.toISOString() } }))
      resetErrors(); // Reset errors when modal is closed
    }
  }, [open])
  const getActions = () => {
    return Actions.map((_, i) => {
      let action = { ..._ };
      action.onClick = (i == 0) ? handleClose : handleSubmitNewTest
      return action
    });
  }
  const handleClose = () => {
    props.onClose()
    resetErrors(); // Reset errors when modal is closed
  }

  const getFields = () => {
    return Fields.Test.map((_) => {
      let field = { ..._ };
      if (_.attribute === "due_date") {
        field.minDate = getDateInFormat()
      }
      return field;
    })
  }
  const getBlueprintOptions = () => {
    let filteredBlueprints = []
    if (state.test && state.test.department && state.test.department.id) {
      filteredBlueprints = blueprints.filter((blueprint) => blueprint.department === state.test.department.id)
      filteredBlueprints = filteredBlueprints.map(({ title, id }) => {
        return { label: title || '', id: id || '' }
      })
    }
    return filteredBlueprints;
  }
  const getOptions = (attribute) => {
    let options;
    switch (attribute) {
      case 'department': options = departments; break;
      case 'responder':
        options = orgUsers.map((_) => {
          return { label: `${_._listname}, ${_.designation}, ${_.department_name}`, id: _.id }
        })
        break;
      case 'continuer_blueprint_id':
        options = getBlueprintOptions(); break;
      default: options = []; break;
    }
    return options
  }
  const handleSubmitNewTest = (e) => {
    e.preventDefault && e.preventDefault();
    if (_ref.current && _ref.current.validate) {
      let valid = _ref.current.validate(e, true);
      if (!valid) {
        return
      }
    }
    const payload = new FormData();

    Fields.Test_create.forEach(({ type, attribute }) => {
      let value = state.test[attribute], key;
      if (type === 'select') {
        value = (value && value.id) ? value.id : value;
      }
      key = attribute === 'responder' ? 'responder[ent_usr_id]' : `continuer[${attribute}]`
      payload.append(key, value)
    });
    dispatch(continuityActions.createTest(payload));
    props.onClose && props.onClose();
  }
  function extractTextFromHTML(htmlString) {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    tempElement.querySelectorAll('span').forEach(span => {
      span.innerHTML = span.innerHTML.replace(/&shy;/g, '');
    });
    let text = tempElement.textContent.trim();
    if (!text || text === 'undefined') {
      return htmlString
    }
    return text;
  }
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const test = { ...state.test }
    test[name] = value;
    let showDeptResponderConfirm = false;
    if (value && name === 'department') {
      let deptResp = getDeptResponderByDept(deptResponders, value.id);
      if (deptResp.responder) {
        test.responder = { label: deptResp.responder_listname, id: deptResp.responder.id }
      } else {
        test.responder = null
      }
    }
    if (value && name === 'responder' && test.department) {
      let deptResp = getDeptResponderByDept(deptResponders, test.department.id);
      showDeptResponderConfirm = deptResp && deptResp.id !== value.id
    }
    if (value && name === 'scenario_text') {
      test.scenario = value
    }
    if (value && name === 'continuer_blueprint_id') {
      let filteredBlueprint = blueprints.filter((blueprint) => (blueprint.id === e.target.value.id))
      if (filteredBlueprint.length > 0) {
        let blueprintScenarioHtml = filteredBlueprint[0].scenario
        let blueprintScenario = extractTextFromHTML(filteredBlueprint[0].scenario)
        test.title = filteredBlueprint[0].title
        test.scenario_text = blueprintScenario
        test.scenario = blueprintScenarioHtml
        test.internal_notes = filteredBlueprint[0].internal_notes
        test.learning = filteredBlueprint[0].learning
        test.observation = filteredBlueprint[0].observation
        test.reference = filteredBlueprint[0].reference
        test.expected_evidence = filteredBlueprint[0].expected_evidence
        test.result = filteredBlueprint[0].result
      }
    }
    setState((_) => ({ ..._, test: test, showDeptResponderConfirm }));
  };

  return (
    <React.Fragment>
      <Modal
        width={600}
        open={open}
        className="new-test-modal"
        actions={getActions()}
        onCancel={props.onClose}
        title={<span className="exo2 f24 c238787">New Test</span>}>
        {
          open &&
          <Form
            showColon
            ref={_ref}
            onSubmit={handleSubmitNewTest}
            className="form row fwarp h-btn"
            formData={{ ...state.test }}
            Fields={getFields()}
            getOptions={getOptions}
            onChange={handleValueChange}
            errors={errors}
            setErrors={setErrors}
          />
        }
      </Modal>
      <DepartmentResponderConfirm
        department={state.test.department}
        responder={state.test.responder}
        open={state.showDeptResponderConfirm}
        onClose={() => {
          setState((_) => ({ ..._, showDeptResponderConfirm: false }))
        }}
      />
    </React.Fragment>
  )
}
