import React from 'react';
import { Button } from "../Button";
import { Eval404 } from "../Assets";
import { Row, Col } from 'antd';
import "./style.scss";

export const PageNotFound = (props) => {
  return (
    <div className='full w-100 h-100 col page-not-found'>
      <Row container className="h-100 w-100">
        <Col item xs={12} sm={12} md={6} lg={6} >
          <div className='col oval-container text-container'>
            <div className='exo2 txt clang italic'>CLANG!</div>
            <div className='bold txt txt-404'>Page Not Found</div>
            <p className='txt-info'>What you're looking for might be renamed, <br />removed, or might not exist!</p>
            <Button disableElevation className='h10 back-home' label='Back to Home Page' onClick={props.onHome} />
          </div>
        </Col>
        <Eval404 alt='Oval-Not-Found' className='oval-404' />
      </Row>
    </div>
  )
}