import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getUserAvatorName, continuityActions, commonActions, isTestResponder, isTestAdvisor, isTestInReview, isTestAbandoned, isTestRedundant } from "../../../store";
import { Form, Button, LinkButton } from "../../../components";
import FormFields from "./FormFields.json";

export const TestRecovery = (props) => {
  const dispatch = useDispatch();
  const { test } = props;
  const _formRef = React.useRef(null);
  const _historyRef = React.useRef(null);
  const [state, setState] = React.useState({ comment: {} })
  const currentUser = useSelector(getCurrentUser);
  const isInvalid = test && String(test.status).trim().toLowerCase() === 'invalid';
  React.useEffect(() => {
    if (_historyRef.current) {
      _historyRef.current.scrollTop = _historyRef.current.scrollHeight;
    }
  }, [test])
  const canEdit = () => {
    let access = true;
    if (isTestInReview(test)) {
      if (isTestResponder(test, currentUser) && !isTestAdvisor(test, currentUser)) {
        access = false;
      }
    } else if (isTestAbandoned(test) || isTestRedundant(test)) {
      access = false;
    }
    return access;
  }
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const comment = state.comment;
    comment[name] = value;
    setState((_) => ({ ..._, comment: comment }))
  }
  const handleCommentSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    const payload = new FormData();
    let shouldUpdate = false;
    let documents = state.comment['documents'],
      comment = String(state.comment['comment'] || '').trim();
    if (Array.isArray(documents) && documents.length > 0) {
      shouldUpdate = true;
      documents.forEach((file, i) => {
        payload.append(`documents[attachment${i}]`, file)
      })
    }
    if (shouldUpdate || comment.length > 0) {
      shouldUpdate = true;
      payload.append(`comment[comment]`, comment)
    }
    if (shouldUpdate) {
      payload.append('continuer_id', test.id);
      dispatch(continuityActions.addTestComment(payload));
    }
    setState((_) => ({ ..._, comment: {} }))
  }
  const handleDeleteFile = (attribute, index) => {
    const comment = state.comment;
    if (Array.isArray(comment[attribute]) && comment[attribute].length > 0) {
      comment[attribute].splice(index, 1)
    }
    setState((_) => ({ ..._, comment: { ...comment } }))
  }
  const handleEvidenceDownload = (attachment, e) => {
    e.preventDefault();
    if (attachment && attachment.encrypt_evidence_url) {
      dispatch(commonActions.downloadAttachment({ attachment: attachment }))
    }
  }
  return (
    <div className="col history-sec h-100">
      <div className="col header h-ctr">
        <span className="f16 exo2 c0085 line-22 caps">recovery</span>
      </div>
      <div className="col f-rest o-hide">
        <div className="col w-100 h-100 oy-auto" ref={_historyRef}>
          {
            (Array.isArray(test.comments) && test.comments.length > 0) ?
              test.comments.map((comment, i) => {
                // console.log("comment", comment);
                return (
                  Boolean(comment.creator) &&
                  <div className="row w-100 v-start comment-card" key={comment.id}>
                    <div className="col h-ctr v-ctr avator f14 c444444">
                      {Boolean(comment.creator) ? getUserAvatorName(comment.creator) : null}
                    </div>
                    <div className="col f-rest">
                      {
                        Boolean(comment.creator) &&
                        <div className="row w-100">
                          <div className="col f-rest c00045 reg">
                            <span className="f12">{comment.creator_name}</span>
                            <span className="f10">{comment.creator.designation}</span>
                            <span className="f10">{comment.creator.department_name}</span>
                          </div>
                          <span className="reg f10 cBFBFBF">{comment.created_at}</span>
                        </div>
                      }
                      {
                        Boolean(comment.comment.length > 0) &&
                        <p className="c00085 f14 reg comment-text txt-justify" dangerouslySetInnerHTML={{ __html: comment.comment }}></p>
                      }
                      {
                        comment.attachments.map((attachment, i) => {
                          let filename = attachment.document_file_name.split('.').slice(0, -1).join('.')
                          let label = `Evidence ${attachment.appendixID}: Continuer-${test.test_id}-${filename}`
                          return (
                            <Button
                              target="_blank"
                              font='f12' className='link-evd' variant='lite' color='#0133CC' key={attachment.id}
                              label={label}
                              onClick={handleEvidenceDownload.bind(null, attachment)}
                            />
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
              :
              <div className="exo2 col w-100 h-100 v-ctr h-ctr c777 f10">
                There are no history right now!
              </div>
          }
        </div>
      </div>
      {
        canEdit() &&
        <div className="row footer v-start">
          <div className="col h-ctr v-ctr avator f14 c444444">
            {getUserAvatorName(currentUser)}
          </div>
          <div className="col w-100">
            <Form
              hideErrorMsg
              className="col"
              ref={_formRef}
              formData={{ ...state.comment }}
              Fields={FormFields.Comment}
              onSubmit={handleCommentSubmit}
              onChange={handleFieldChange}
              onDeleteFile={handleDeleteFile} />
            <Button label='Submit' className='btn-submit' onClick={handleCommentSubmit} />
          </div>
        </div>
      }
    </div>
  )
}