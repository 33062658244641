import originAxios from "axios";
// import { KEY_NM_API } from "neumetric-components";
const KEY_NM_API = 'NM-API-Key'

const HOST_SERVER = process.env.REACT_APP_API_SERVER || 'http://localhost:3000';
const AUTH_TOKEN = "AUTH_TOKEN";
const REFRESH_TOKEN = "refresh-token";
const ACCESS_TOKEN = "access-token";

const HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json"
}
const FormDataHeader = { 'Content-Type': 'multipart/form-data' }

function errorhandler(errorRes) {
  const { response } = errorRes;
  if (response) {
    throw { status: errorRes.response.status, data: errorRes.response.data };
  } else {
    throw { data: { message: "error", success: false, error: errorRes } };
  }
}

const axiosInst = originAxios.create({
  baseURL: HOST_SERVER,
});
class MyAxios {
  getImageFile(path) {
    const token = localStorage.getItem(AUTH_TOKEN)
    return `${HOST_SERVER}${path}?token=${token}`
  }
  getFullPath(path) {
    return `${HOST_SERVER}${path}`
  }
  getFormDataContentType() {
    return FormDataHeader;
  }
  getAuthTokenHeader() {
    let authHeader = {}, authToken = localStorage.getItem(KEY_NM_API);
    // authToken = '71PvQt5a7XLIJ4FLbOa5lI9Ncz3huzbqRqH5Vvcu4yeYgy212b02LjxjxCUz';
    if (authToken) {
      authHeader[KEY_NM_API] = authToken
    }
    return authHeader
  }
  getHeaders(additional = {}) {
    let newheaders = this.getAuthTokenHeader();
    return { headers: { ...HEADERS, ...newheaders, ...additional } }
  }
  async responseHandler(response) {
    if (response.headers && response.headers[ACCESS_TOKEN]) {
      localStorage.setItem(AUTH_TOKEN, response.headers[ACCESS_TOKEN])
    }
    return response.data
  }
  get(path, newheaders) {
    return axiosInst.get(path, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler);
  }
  getExport(path) {
    return fetch(axios.getFullPath(path), { method: "GET", headers: axios.getAuthTokenHeader() })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          return { message: response.statusText, error: true };
        }
      });
  }
  post(path, body, newheaders) {
    return axiosInst.post((HOST_SERVER + path), body, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  delete(path, newheaders) {
    return axiosInst.delete((HOST_SERVER + path), this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  put(path, body, newheaders) {
    return axiosInst.put((HOST_SERVER + path), body, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  patch(path, body, newheaders) {
    return new Promise((resolve, reject) => {
      this.getHeaders(newheaders)
        .then(headers => {
          if (body instanceof FormData) {
            headers['Content-Type'] = 'multipart/form-data'
          }
          return axiosInst.patch(HOST_SERVER + path, body, headers)
        })
        .then(res => {
          return this.responseHandler(res)
        }).then(resolve).catch(reject)
    }).catch(errorhandler)
  }
  postExport(path, body) {
    return fetch(axios.getFullPath(path), { method: "POST", body: JSON.stringify(body), headers: { "Content-Type": "application/json", ...axios.getAuthTokenHeader() } })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          return { message: response.statusText, error: true };
        }
      });
  }
}


export const axios = new MyAxios();