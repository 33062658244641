import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../../components";
import { continuityActions, getTests, getTestsTotalCount, isTestClosed, isTestResponder, getCurrentUser, isOrganizer, isAuditee } from "../../../store";

const Columns = [
  { title: 'Test ID', dataIndex: 'test_id', key: 'test_id', width: '160px', className: 'exo2 f14' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '150px', className: 'exo2 f14', sort: true, render: 'color-text', cellClassName: '' },
  { title: 'Test Title', dataIndex: 'title', key: 'title', width: '50%', search: true, render: 'link', variant: 'lite', color: '#0033CC', className: 'exo2 f14', supportServerSearch: true },
  { title: 'Department', dataIndex: 'department', key: 'department', width: '15%', className: 'exo2 f14' },
  { title: 'Due Date', dataIndex: 'due_date', key: 'due_date', width: '20%', className: 'exo2 f14 text-center' }
]

const getPage = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('page') || 1
}

export const TestsTracker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tests = useSelector(getTests);
  const testsCount = useSelector(getTestsTotalCount);
  const currentUser = useSelector(getCurrentUser);
  const _filter = React.useRef({ search: '', defaultPage: getPage(), pageNo: getPage() })
  React.useEffect(() => {
    fetchTests();
  }, [])
  const fetchTests = () => {
    const { pageNo, search } = _filter.current;
    dispatch(continuityActions.fetchTests({ pageNo, search }))
    dispatch(continuityActions.setCurrentTest({ test: null }))
  }
  const handlePageChange = (pageNo) => {
    navigate(`${window.location.pathname}?page=${pageNo}`)
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchTests()
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchTests();
  }
  const getContinuityTests = () => {
    if (Array.isArray(tests) && isAuditee(currentUser)) {
      return tests.map((_) => {
        const test = { ..._ };
        if (isTestClosed(test) && isTestResponder(test, currentUser)) {
          test.unlink = true;
        }
        return test;
      })
    }
    return tests
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <DataTable
        noSelection
        emptyMessage="There are no Tests right now!"
        Columns={Columns}
        rows={getContinuityTests()}
        totalCount={testsCount}
        onSearch={handleSearch}
        defaultPage={_filter.current.defaultPage}
        onPageChange={handlePageChange}
      />
    </div>
  )
}