import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  departments: [],
  documentTypes: [],
  taskPriorityTypes: [],
  documentClassification: [],
  incidentDeptResponders: [],
  continuityDeptResponders: []
}

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    fetchDepartments: () => {},
    fetchDocumentTypes: () => {},
    fetchTaskPriorities: () => {},
    fetchDocumentClassifications: () => {},
    fetchIncidentDeptResponders: () => {},
    fetchContinuityDeptResponders: () => {},
    updateDeptIncidentResponder: () => {},
    updateDeptContinuityResponder: () => {},
    downloadAttachment: () => {},
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
    setDocumentTypes: (state, action) => {
      state.documentTypes = action.payload
    },
    setDocumentClassifications: (state, action) => {
      state.documentClassification = action.payload
    },
    setIncidentDeptResponders: (state, action) => {
      state.incidentDeptResponders = action.payload
    },
    setContinuityDeptResponders: (state, action) => {
      state.continuityDeptResponders = action.payload
    },
    setTaskProrities: (state, action) => {
      state.taskPriorityTypes = action.payload
    },
  }
})
export const commonActions = commonSlice.actions;

ReducerRegistry.register("common", commonSlice.reducer)