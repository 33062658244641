import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../Button";
import { Form } from "../Input";
import { getDateInFullYearFormat, getCurrentUser } from "../../store";
import { ContinuerLogo, NeumetricLogo, FusionLogo } from "../Assets";
import { OvalLoading } from "../Screens";

const Field = { attribute: 'dateRange', type: 'select' }
export const TableComponent = (props) => {
  const { title, dateRange, getOptions, handleFieldChange, Columns, rows, brandname, isExporting } = props
  return (
    <div>
      <div className="d-flex">
        <div className="f20 exo2 bold c238787 title flex-grow-1 mb-title">
          <div className="exo2">{title}</div>
        </div>
        {
          !isExporting ?
            <>
              <div className="" >
                <Form
                  className='col'
                  formData={{ dateRange }}
                  Fields={[Field]}
                  getOptions={getOptions}
                  onChange={handleFieldChange} />
                <div className="d-flex h-end" >
                  <div className='exo2 f12 mr'>Stats as on </div> <b className="exo2 f12"> {getDateInFullYearFormat()}</b>
                </div>
              </div>
            </>
            :
            <div className="d-flex h-end v-ctr mb-stats" >
              <div className='exo2 f12 mr'>Stats as on </div> <b className="exo2 f12 mr"> {getDateInFullYearFormat()}</b><div className="exo2 f12">for {dateRange.label}</div>
            </div>}
      </div>
      <table className="stats-tbl">
        <thead>
          <tr>
            {
              Columns.map((col) => {
                return (
                  <th style={{ width: col.width || '100px' }} className={`f16 bold exo2 header-cell ${col.headerClassName}`} dangerouslySetInnerHTML={{ __html: col.label }} ></th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            Array.isArray(rows) && rows.length > 0 ?
              (rows.map((row, i) => {
                return (
                  <tr key={i}>
                    {
                      Columns.map((col) => {
                        let value = row[col.attribute];
                        let isLabelCell = col.attribute === 'label';
                        return (
                          <td key={col.attribute} className={`f16 exo2 reg cell c238787`}>
                            <div className={`row w-100 h-100 ${isLabelCell ? 'v-ctr' : 'h-ctr'} `}>
                              <span className="f16 exo2 bold">{value}</span>
                            </div>
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
              )
              :
              (
                <tr>
                  <td className="f16 bold cell v-ctr h-ctr exo2">{brandname}</td>
                  <td colSpan={Columns.length} className='f16 reg cell c238787 v-ctr h-ctr' style={{ paddingLeft: '20px' }}>
                    No data to display!
                  </td>
                </tr>
              )
          }
        </tbody>
      </table>
    </div>
  )
}
export const StatisticsTable = (props) => {
  const { rows, title, onDateChange, onExport, Columns, DateRangeEnums, statusAs, isExporting } = props;
  const [dateRange, setDateRange] = React.useState('')
  const [imageSrc, setImageSrc] = React.useState('');
  const user = useSelector(getCurrentUser);
  const brandname = user.ent_org.brandname;

  React.useEffect(() => {
    if (user) {
      if (user.logoBlob) {
        const imageUrl = URL.createObjectURL(user.logoBlob);
        setImageSrc(imageUrl);
      }
    }
  }, [user])
  React.useEffect(() => {
    if (DateRangeEnums.length > 0) {
      setDateRange(DateRangeEnums[6])
    }
  }, [DateRangeEnums])
  React.useEffect(() => {
    onDateChange && onDateChange(dateRange);
  }, [dateRange])
  const getOptions = () => {
    return DateRangeEnums
  }
  const handleFieldChange = (e) => {
    setDateRange(e.target.value)
  };

  return (
    <div className={`col w-100 ${props.className || ''}`}>
      <div style={{ height: '90%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 1)', position: 'fixed', display: 'none', zIndex: '999' }} id='loader-component'>
        <OvalLoading/>
      </div>
      <div className="row h-end">
        <Button
          className='row row-reverse btn-export'
          color='#0133CC'
          iconColor='c0133CC'
          font='f14'
          onClick={onExport && onExport.bind(null, dateRange)}
          variant='lite' label='Export' icon='icon-export' />
      </div>
      <TableComponent
        title={title} dateRange={dateRange} getOptions={getOptions} isExporting={isExporting}
        handleFieldChange={handleFieldChange} Columns={Columns} rows={rows} brandname={brandname}
      />
      {/* Table for export format */}
      <div id='continuity_table' className="continuity-table" style={{ display: 'none' }}>
        <div className="export-page-container">
          <div className="export-top-left-logo"><ContinuerLogo className='logo' /></div>
          <div className="export-top-right-logo"> {imageSrc && <img src={imageSrc} alt='logo2' crossOrigin="anonymous" className="logo" />}</div>
          <div className="export-bottom-left-logo"> <FusionLogo className='logo' /></div>
          <div className="export-bottom-right-logo"> <NeumetricLogo className='small_logo' /></div>
          <div className="reg f9 export-bottom-page-no">- Page 1 of 1 -</div>

          <div className="export-center-content">
            <TableComponent
              title={title} dateRange={dateRange} getOptions={getOptions} isExporting={true}
              handleFieldChange={handleFieldChange} Columns={Columns} rows={rows} brandname={brandname}
            />
          </div>
        </div>
      </div>
    </div >
  )
}
