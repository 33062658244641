import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, requestHandler, axios, setAlertAndLoading, getUserDetails, sortByProperty, fetchS3ObjectAsBlob } from "../app";
import { commonActions } from "./slice";
import { saveAs } from 'file-saver';

function* fetchDepartments() {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/department_list');
    res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
    res.sort(sortByProperty.bind(null, 'label'))
    yield put(commonActions.setDepartments(res))
  }, "Failed to Fetch Departments", false);
}

function* fetchDocumentTypes() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/doc_type_list');
    res = Object.keys(res.types || {}).map((_) => ({ label: _, attribute: _, id: _ }));
    yield put(commonActions.setDocumentTypes(res))
  }, "Failed to Fetch Types", false);
}
function* fetchDocumentClassifications() {
  yield requestHandler(function* () {
    let res = yield axios.get('/centrals/doc_classi_list');
    let types = (res.types || {}), temp = Object.keys(types), list = [];
    for (let i = 0; i < temp.length; i++) {
      let cls = temp[i]
      list[types[cls]] = { label: cls, attribute: cls, id: cls }
    }
    yield put(commonActions.setDocumentClassifications(list))
  }, "Failed to Fetch Classification", false);
}
function* fetchContinuityDeptResponders() {
  yield requestHandler(function* () {
    let res = yield axios.get('/continuers/con_dept_responders');
    let list = res.dept_responders ? res.dept_responders : res;
    list = Array.isArray(list) ? list : [];
    list = list.map((item) => {
      item.responder_name = '';
      getUserDetails(item, 'responder')
      return { ...item }
    });
    yield put(commonActions.setContinuityDeptResponders(list))
  }, "Failed to Fetch Department Responders", false);
}
function* fetchIncidentDeptResponders() {
  yield requestHandler(function* () {
    let res = yield axios.get('/incidents/inc_dept_responders');
    let list = res.dept_responders ? res.dept_responders : res;
    list = Array.isArray(list) ? list : [];
    list = list.map((item) => {
      item.responder_name = '';
      getUserDetails(item, 'responder')
      return { ...item }
    });
    yield put(commonActions.setDeptResponders(list))
  }, "Failed to Fetch Department Responders", false);
}
function* updateDeptIncidentResponder({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(false);
    const { department, userId } = payload;
    const body = { incident_dept_responders: { department_name: department, responder_id: userId } }
    yield axios.post('/incidents/set_inc_dept_reponder', body);
    yield setAlertAndLoading(null, { message: "Reponder Updated Successfully." })
    yield put(commonActions.fetchIncidentDeptResponders())
  }, "Failed to Update Department Responder", false);
}
function* updateDeptContinuityResponder({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(false);
    const { department, userId } = payload;
    const body = { incident_dept_responders: { department_name: department, responder_id: userId } }
    const res = yield axios.post('/continuers/set_con_dept_reponder', body);
    if (res.dept_responder) {
      yield setAlertAndLoading(null, { message: "Reponder Updated Successfully." })
      yield put(commonActions.fetchContinuityDeptResponders())
    } else {
      yield setAlertAndLoading(null, { success: false, message: "Failed to Responder: " + (res.message + '') })
    }
  }, "Failed to Update Department Responder", false);
}
function* fetchTaskPriorities() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/tasks/priority_list`);
    let list = res.priority_list ? res.priority_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    })
    yield put(commonActions.setTaskProrities(list))
  }, "Failed to load Task Priority list");
}
function* downloadAttachment({payload}) {
  yield requestHandler(function* () {
    const { attachment } = payload;
    const blob = yield fetchS3ObjectAsBlob(attachment.encrypt_evidence_url);
    if (blob instanceof Blob) {
      saveAs(blob, attachment.document_file_name);
    }
  }, "Failed to download attachement");
}

SagaRegistry.register(function* userSaga() {
  yield takeEvery("common/fetchDepartments", fetchDepartments);
  yield takeEvery("common/fetchDocumentTypes", fetchDocumentTypes);
  yield takeEvery("common/fetchTaskPriorities", fetchTaskPriorities);
  yield takeEvery("common/downloadAttachment", downloadAttachment);
  yield takeEvery("common/fetchDocumentClassifications", fetchDocumentClassifications);
  yield takeEvery("common/fetchIncidentDeptResponders", fetchIncidentDeptResponders);
  yield takeEvery("common/fetchContinuityDeptResponders", fetchContinuityDeptResponders);
  yield takeEvery("common/updateDeptIncidentResponder", updateDeptIncidentResponder);
  yield takeEvery("common/updateDeptContinuityResponder", updateDeptContinuityResponder);
})