import React from 'react';
import { CKEditor } from '@neumetric/richtext-editor-react';
import { AppErrorBoundry } from "../Screens";

const CustomCSS = `
  @import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
  .tbl-cont-line{
    width:100%;
    clear:both;
  }
  .tbl-cont-line .section{
    float:left;
  }
  .tbl-cont-line .pageno{
    float:right;
  }
`;

const CKConfig = {
  toolbar: [
    { name: "clipboard", items: ['Cut', 'Copy', '-', 'Undo', 'Redo'] },
    { name: "editing", items: ['Find', 'Replace'] },
    { name: "basicstyles", items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', '-', 'CopyFormatting', 'RemoveFormat'] },
    { name: "paragraph", items: ['indent', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList', '-', 'BidiLtr', 'BidiRtl'] },
    { name: "links", items: ['Link', 'Unlink', 'Anchor'] },
    { name: "insert", items: ['SpecialChar', 'Image', 'Table'] },
    { name: "styles", items: ['Font', 'FontSize', 'Styles', 'Format'] },
    { name: "colors", items: ['TextColor', 'BGColor'] }
  ],
  font_names: 'Roboto;Exo 2;',
  font_defaultLabel: 'Roboto',
  fontSize_defaultLabel: '12px',
  font_style: {
    element: 'span',
    styles: { 'font-family': '"#(family)"' },
    overrides: [{ element: 'font', attributes: { 'face': null } }]
  },
  colorButton_backStyle: {
    element: 'span',
    styles: { 'background-color': '#(color)' }
  },
  colorButton_foreStyle: {
    element: 'span',
    styles: { color: '#(color)' }
  },
  removeButtons: 'Paste,PasteText,PasteFromWord,contextmenu',
  tabSpaces: 4,
  fullPage: false,
  resize_enabled: false,
  colorButton_enableMore: true,
  removePlugins: 'resize',
  editorplaceholder: 'Start typing here...',
  fontSize_sizes: '8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;18/18px;24/24px;30/30px;36/36px;48/48px;60/60px;72/72px;90/90px;',
  extraPlugins: 'font,colorbutton,justify,contextmenu,editorplaceholder',
  contentsCss: CustomCSS
}
const InitialData = `<span style="font-family:Roboto; font-size:14px">&shy;</span>`;

export const NMCKEditor = (props) => {
  const { type, initialData, title, config, ...rest } = props; 
  return (
    <AppErrorBoundry>
      <CKEditor
        config={{ ...CKConfig, ...(config || {}) }}
        type={type}
        initData={initialData || InitialData}
        {...rest}
      />
    </AppErrorBoundry>
  )
}