import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  currentTest: null,
  tests: null,
  totalTests: 0,
  blueprints: [],
  statusList: [],
  dateRangeEnumns: [],
  statistics: { severity: [], department: [] }
}

const continuitySlice = createSlice({
  name: "continuity",
  initialState: initialState,
  reducers: {
    fetchTests: () => { },
    fetchTest: () => { },
    createTest:  () => {},
    updateTest: () => {},
    exportDeptStats: () => {},
    fetchPriorities: () => { },
    fetchStatusList: () => {},
    downloadTestReport: () => {},
    shareTestReport: () => {},
    fetchActiveTestBluprints: () => {},
    fetchDateRangeEnums: () => {},
    updateTestResponder: () => {},
    removeTestResponder: () => {},
    addTestComment: () => {},
    createTestTask: () => {},
    fetchDeptStats: () => {},
    setTests: (state, action) => {
      state.tests = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalTests = action.payload
    },
    setBlueprints: (state, action) => {
      state.blueprints = action.payload;
    },
    setCurrentTest: (state, action) => {
      state.currentTest = action.payload.test;
    },
    setTestStatusList: (state, action) => {
      state.statusList = action.payload
    },
    setDateRangeEnums: (state, action) => {
      state.dateRangeEnumns = action.payload
    },
    setStatistics: (state, action) => {
      const { department } = action.payload;
      if (department) {
        state.statistics = { ...state.statistics, department: department }
      }
    }
  }
})
export const continuityActions = continuitySlice.actions;

ReducerRegistry.register("continuity", continuitySlice.reducer)